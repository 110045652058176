<template>
  <div class="container">
    <div class="banner">
      <div class="text">数/据/交/易</div>
    </div>
    <div class="main">
      <div class="u-flex u-m-t-60 u-col-top">
        <div class="wi45 u-m-r-60">
          <img class="wi100" src="../../../../src/static/img/data-support/u1433.png" />

        </div>
        <div class="u-flex-1 u-flex u-flex-column u-row-center u-col-center">
          <div class="u-flex u-row-center u-m-b-20">
            <div class="ball-1"></div>
            <div class="auto-title u-font-30">数字营销</div>
            <div class="ball-2">
              <div class="ball-3"></div>
            </div>
          </div>
          <div class="wi100 lh2 u-content-color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在数字经济时代，传统企业实现数字化时，必须把数字营销作为一个重要的方面来关注，变革原本不能满足需要的营销思想、模式和策略，实现新的营销方式。数字营销将尽可能地利用先进的计算机网络技术，以最有效、最省钱地谋求新的市场的开拓和新的消费者的挖掘。</div>
          <div class="u-flex u-row-between wi100 u-m-t-30">
            <div class="btn u-flex">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">数字营销系统</div>
            </div>
            <div class="btn u-flex">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">电子商务系统</div>
            </div>
          </div>
        </div>
      </div>

      <div class="u-flex u-m-t-60 u-col-top u-row-reverse">
        <div class="wi45 u-m-l-60">
          <img class="wi100" src="../../../../src/static/img/data-support/u1464.jpg" />
        </div>
        <div class="u-flex-1 u-flex u-flex-column u-row-center u-col-center">
          <div class="u-flex u-row-center u-m-b-20">
            <div class="ball-1"></div>
            <div class="auto-title u-font-30">设计工具</div>
            <div class="ball-2">
              <div class="ball-3"></div>
            </div>
          </div>
          <div class="wi100 lh2 u-content-color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;设计工具是现代设计师必不可少的工具之一，它们可以帮助设计师更快速、更高效地完成设计任务。设计工具包括各种软件和硬件。这些工具不仅提供了丰富的设计元素和模板，还可以让设计师轻松地进行图形编辑、排版、交互设计等操作。同时，设计工具也支持多人协作，方便团队合作完成项目。</div>
          <div class="u-flex u-row-between wi100 u-m-t-30">
            <div class="btn u-flex">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">Meta20 设计系统</div>
            </div>
            <div class="btn u-flex">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">装修设计系统</div>
            </div>
          </div>
        </div>
      </div>

      <div class="u-flex u-m-t-60 u-col-top">
        <div class="wi45 u-m-r-60">
          <img class="wi100" src="../../../../src/static/img/data-support/u1465.jpg" />
        </div>
        <div class="u-flex-1 u-flex u-flex-column u-row-center u-col-center">
          <div class="u-flex u-row-center u-m-b-20">
            <div class="ball-1"></div>
            <div class="auto-title u-font-30">生产管理</div>
            <div class="ball-2">
              <div class="ball-3"></div>
            </div>
          </div>
          <div class="wi100 lh2 u-content-color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;生产管理是企业生产过程中的重要环节，它涉及到生产计划、物料采购、生产调度、质量控制、库存管理等方面。通过科学合理的生产管理，可以提高生产效率、降低成本、提高产品质量，从而增强企业的竞争力。生产管理需要依靠先进的信息技术和管理方法，如ERP系统、精益生产、6σ管理等，以实现生产过程的优化和协调。</div>
          <div class="u-flex u-flex-wrap u-row-between wi100">
            <div class="btn u-flex u-m-t-30">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">虚拟制造系统</div>
            </div>
            <div class="btn u-flex u-m-t-30">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">订单管理系统柜</div>
            </div>
            <div class="btn u-flex u-m-t-20">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">生产管理系统</div>
            </div>
          </div>
        </div>
      </div>

      <div class="u-flex u-m-t-60 u-col-top u-row-reverse u-p-b-80">
        <div class="wi45 u-m-l-60">
          <img class="wi100" src="../../../../src/static/img/data-support/u1464.jpg" />
        </div>
        <div class="u-flex-1 u-flex u-flex-column u-row-center u-col-center">
          <div class="u-flex u-row-center u-m-b-20">
            <div class="ball-1"></div>
            <div class="auto-title u-font-30">智能制造</div>
            <div class="ball-2">
              <div class="ball-3"></div>
            </div>
          </div>
          <div class="wi100 lh2 u-content-color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能制造是指利用先进的信息技术、自动化技术和智能化技术，实现生产过程的智能化、高效化和可持续化。智能制造需要依靠先进的技术和设备，如工业机器人、3D打印、物联网、云计算等，同时也需要注重人才培养和团队协作，建立高效的管理体系和流程。</div>
          <div class="u-flex u-flex-wrap u-row-between wi100">
            <div class="btn u-flex u-m-t-30">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">智能开料工作站</div>
            </div>
            <div class="btn u-flex u-m-t-30">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">智能SCADA系统</div>
            </div>
            <div class="btn u-flex u-m-t-20">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">深度视觉拆码垛工作站</div>
            </div>
            <div class="btn u-flex u-m-t-20">
              <icon-svg name="web"></icon-svg>
              <div class="fwb u-m-l-10">视觉板件检测系统</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped lang="scss">
::v-deep .icon-svg {
  width: 1.3em;
  height: 1.3em;
}
.banner{
  width: 100%;
  height: 460px;
  background: url("../../../static/img/data-support/u1325.png") no-repeat center center;
  background-size: cover;
  margin-top: -60px;
  overflow: hidden;
  .text{
    font-size: 70px;
    letter-spacing: 20px;
    color: #fff;
    text-align: center;
    padding-top: 200px;
  }
}
.ball-1{
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-image: linear-gradient( -45deg, #FD00E8FF, #6E8EE9FF );
  margin-bottom: 60px;
  margin-right: 10px;
}
.ball-2{
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-image: linear-gradient( -45deg, #fcd005, #7cb301);
  margin-top: 60px;
  margin-left: -6px;
}
.ball-3{
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-image: linear-gradient( -45deg, #0c86f3, #7cb301);
  margin-top: 60px;
  margin-left: -6px;
}
.main{
  width: 1200px;
  margin: 0 auto;
  .btn{
    padding: 15px;
    background-color: #f0f0f0;
    width: 40%;
  }
}
</style>
